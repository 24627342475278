<template>
  <div class="studyPage">
    <Header />
    <Nav :route="navList" />
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Nav from "@/components/nav";
export default {
  name: "study",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      version: "low", //low=低版本,middle=中版本,high=高版本
      navList: [
        {
          title: "基础练习记录",
          name: "BasicPractiseTable",
        },
      ],
      // navList:[{
      //     title:'基础练习记录',
      //     name:'BasicPractise',
      //     children:[{
      //         title:'数据统计',
      //         name:'BasicPractiseTable',
      //     },{
      //         title:'图表分析',
      //         name:'BasicPractiseChart',
      //     }]
      // },{
      //     title:'练习任务记录',
      //     name:'PractiseTaskTable',
      //     children:[{
      //         title:'数据统计',
      //         name:'PractiseTaskTable',
      //     },{
      //         title:'图表分析',
      //         name:'PractiseTaskChart',
      //     }]
      // },{
      //     title:'考核任务记录',
      //     name:'AssessmentTaskTable',
      //     children:[{
      //         title:'数据统计',
      //         name:'AssessmentTaskTable',
      //     },{
      //         title:'图表分析',
      //         name:'AssessmentTaskChart',
      //     }]
      // }],
    };
  },
  mounted() {
    this.getHospitalVersion();
  },
  methods: {
    async getHospitalVersion() {
      try {
        // let res = await this.$http.get("/rest-api/home/hospitalVersion");
        // this.version = res.data.data.version;
        this.version = JSON.parse(localStorage.getItem("Version"));
        if (this.version == "high") {
          this.navList = [
            {
              title: "基础练习记录",
              name: "BasicPractiseTable",
            },
            {
              title: "练习任务记录",
              name: "PractiseTaskTable",
            },
            {
              title: "考核任务记录",
              name: "AssessmentTaskTable",
            },
          ];
        } else if (this.version == "middle") {
          this.navList = [
            {
              title: "基础练习记录",
              name: "BasicPractiseTable",
            },
            {
              title: "练习任务记录",
              name: "PractiseTaskTable",
            },
          ];
        } else {
          this.navList = [
            {
              title: "基础练习记录",
              name: "BasicPractiseTable",
            },
          ];
        }
        console.log(this.navList, "  this.navList");
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>